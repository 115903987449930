// @ts-ignore
import * as Sentry from '@sentry/browser';

export const setSentryBreadcrumbsInfo = (polyfill) =>
  Sentry.addBreadcrumb({
    category: 'polyfills',
    message: `add ${polyfill}`,
    level: 'info',
  });

export const setSentryBreadcrumbsError = (error) =>
  Sentry.addBreadcrumb({
    category: 'polyfills',
    message: error,
    level: 'error',
  });
