import * as Sentry from '@sentry/browser';

import libAxios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import qs from 'qs';

import { getResponseCamelization } from 'helpers/api';
import { eternity, isObject } from 'helpers/common';

import { fallbackLng, i18nInstance } from 'libs/i18n';
import { authService } from 'services/authService';
import { RequestContext, RequestFn } from 'types/api';
import { DeepUnpack, RequestMethod, Unpack } from 'types/common';

import { SENTRY_CAT } from '../libs/sentry';

function getStaticGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  if (process.env.NODE_ENV === 'development') {
    headers.Dev = '1';

    if (process.env.REACT_APP_DEV_DOMAIN) {
      headers['Dev-Domain'] = process.env.REACT_APP_DEV_DOMAIN;
    }

    if (process.env.REACT_APP_DEV_AUTHENTICATED_ACCOUNT) {
      headers['Dev-Authenticated-Account'] =
        process.env.REACT_APP_DEV_AUTHENTICATED_ACCOUNT;
    }

    if (process.env.REACT_APP_RECAPTCHA_DISABLED) {
      headers['Dev-Recaptcha-Disabled'] = '1';
    }
  }

  return headers;
}

function getDynamicGlobalHeaders() {
  const headers: { [name: string]: string } = {};

  headers.Lng = (i18nInstance && i18nInstance.language) || fallbackLng;

  return headers;
}

const axiosGlobalConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_URL,
  timeout: parseInt(process.env.REACT_APP_REQUEST_TIMEOUT || '20') * 1000,
  headers: getStaticGlobalHeaders(),
};

axiosGlobalConfig.transformResponse = [getResponseCamelization()];
axiosGlobalConfig.paramsSerializer = (params) =>
  qs.stringify(params, { arrayFormat: 'repeat' });

const axios = libAxios.create(axiosGlobalConfig);

const authExpiredErrorCode = 1000;
const unexpectedErrorCode = 2000;

axios.interceptors.response.use(
  (response) => {
    if (isBlobDownload(response.data)) return response;

    if (response.data.errorCode != null && response.data.errorCode !== 0) {
      Sentry.addBreadcrumb({
        category: SENTRY_CAT.xhr,
        message: `Error code ${response.data.errorCode} for ${response.request.responseURL}`,
        level: 'warning',
        data: {
          code: response.data.errorCode,
          message: response.data.message,
          data: response.data.data,
        },
      });

      if (response.data.errorCode === authExpiredErrorCode && authService) {
        Sentry.addBreadcrumb({
          category: 'auth',
          message: 'Session expired',
          level: 'info',
        });

        authService.setIsAuthenticated(false);

        return eternity;
      }

      if (response.data.errorCode === unexpectedErrorCode) {
        Sentry.captureMessage(
          `Unexpected error: ${response.data.message} (${response.data.errorCode}) for ${response.request.responseURL}`,
          'error'
        );
      }

      return Promise.reject(response);
    }

    if (response.data && typeof response.data.data !== 'undefined') {
      response.data = response.data.data;
    } else {
      response.data = {};
    }

    return response;
  },
  (error) => {
    Sentry.captureException(error);

    return Promise.reject(error.response);
  }
);

export function request<T = any>(
  this: AxiosRequestConfig | void,
  method: RequestMethod,
  uri: string,
  data?: object,
  functionScopedConfig?: AxiosRequestConfig
): AxiosPromise<T> {
  const conf: AxiosRequestConfig = {
    method,
    url: uri,
    [method === 'get' ? 'params' : 'data']: data,
    ...this,
    ...functionScopedConfig,
  };

  const dynamicGlobalHeaders = getDynamicGlobalHeaders();

  if (conf.headers == null) {
    conf.headers = dynamicGlobalHeaders;
  } else {
    conf.headers = { ...dynamicGlobalHeaders, ...conf.headers };
  }

  return axios(conf);
}

export function factory<Args extends any[], Return>(
  handler: (r: typeof request) => RequestFn<Args, Return>
) {
  return function (this: RequestContext, ...rest: Args) {
    const r = request.bind(this) as typeof request; // bind looses generic

    return handler(r)(...rest);
  };
}

export type APIParams<T extends object | any[]> = {
  [K in keyof T]: T[K] extends (infer U)[]
    ? U extends any[] | object
      ? { [N in K]: APIParams<DeepUnpack<U>>[] }
      : K
    : T[K] extends object
      ? {
          [R in K]: {
            [L in keyof T[K]]: T[K][L] extends any[] | object
              ? APIParams<DeepUnpack<T[K]>>
              : L;
          }[keyof T[K]][];
        }
      : K;
}[keyof T];

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I
) => void
  ? I
  : never;

type PeekKey<T, U> = U extends keyof T ? T[U] : never;

type PeekAsObject<T, U> = U extends keyof T ? { [K in U]: T[U] } : never;

type UnpackAndPack<T, U> = T extends (infer A)[]
  ? A extends (infer B)[]
    ? B extends (infer C)[]
      ? C extends (infer D)[]
        ? UnionToIntersection<PeekAsObject<D, U>>[][][][]
        : UnionToIntersection<PeekAsObject<C, U>>[][][]
      : UnionToIntersection<PeekAsObject<B, U>>[][]
    : UnionToIntersection<PeekAsObject<A, U>>[]
  : T;

type PeekParamsFromObject<T, U> = {
  [K in keyof T]: K extends keyof U
    ? { [R in K]: UnpackAndPack<PeekKey<T, K>, Unpack<PeekKey<U, K>>> }
    : never;
}[keyof T];

export type PeekAPIParams<T, U> = UnionToIntersection<
  U extends keyof T ? { [K in U]: T[U] } : PeekParamsFromObject<T, U>
>;

export interface GoogleAuthTwoFactor {
  gcode: string;
}

export interface SmsAuthTwoFactor {
  code: string;
  guid: string;
}

export type TwoFactorBase = GoogleAuthTwoFactor;

export type TwoFactor = TwoFactorBase | SmsAuthTwoFactor | Record<never, never>;

export interface BlobDownload {
  filename: string;
  blob: Blob;
}

export function isBlobDownload(data: any): data is BlobDownload {
  return (
    isObject(data) &&
    Object.prototype.hasOwnProperty.call(data, 'filename') &&
    Object.prototype.hasOwnProperty.call(data, 'blob') &&
    data.blob instanceof Blob
  );
}

export enum SmsOperations {
  None = 0,

  ChangeAuthOperation = 12,
  RemoveAuthOperation = 13,
  ChangeAuthMethod = 15,
  RemoveAuthMethod = 16,

  CreatePayment = 17,
  ChangeShop = 18,
  ChangeShopInputPaymethodsPayways = 19,
  CreateShopInvoiceRefund = 20,
  CreateShopPayment = 21,
  CreateShopTransfer = 22,
  CreateDepositRefund = 26,
}

export enum AppType {
  BUSINESS,
  PERSONAL,
}
