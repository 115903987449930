import React, { FC, PropsWithChildren, useEffect } from 'react';

import * as Sentry from '@sentry/browser';

import { FORBIDDEN_ERROR_CODE, useApi } from 'hooks/api';

import { isAuthenticated as isAuthenticatedPersonalRequest } from 'api/personal/auth';
import { isAuthenticated as isAuthenticatedShopRequest } from 'api/shop/auth';

import { InternalErrorBlock } from 'components/internalError';
import { Loader } from 'components/loader';

import { ForbiddenPage } from 'modules/forbiddenPage';

import { setAuthService } from 'services/authService';

import { ShopAuthService } from './business';
import { AuthServiceContext } from './context';
import { PersonalAuthService } from './personal';

const isAuthenticatedRequest =
  process.env.REACT_APP_BUILD_TYPE === 'business'
    ? isAuthenticatedShopRequest
    : isAuthenticatedPersonalRequest;

const AuthService =
  process.env.REACT_APP_BUILD_TYPE === 'business'
    ? ShopAuthService
    : PersonalAuthService;

export const AuthServiceProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const [
    checkAuthentication,
    {
      data: isAuthenticated,
      pending: authenticationCheckPending,
      error: authenticationCheckError,
      errorMessage: authenticationCheckErrorMessage,
      errorData: authenticationCheckErrorData,
    },
  ] = useApi(
    isAuthenticatedRequest,
    { pending: true },
    { dispatchOnCancel: true }
  );

  useEffect(() => {
    checkAuthentication();
  }, [checkAuthentication]);

  useEffect(() => {
    if (authenticationCheckError != null) {
      Sentry.captureMessage(
        `AUTH REQUEST FAILED\nCode: ${authenticationCheckError}\nMessage: ${authenticationCheckErrorMessage}`,
        'fatal'
      );
    }
  }, [authenticationCheckError, authenticationCheckErrorMessage]);

  if (authenticationCheckPending) {
    return <Loader />;
  }

  if (authenticationCheckError === FORBIDDEN_ERROR_CODE) {
    return (
      <ForbiddenPage
        ip={authenticationCheckErrorData.ip}
        countryCode={authenticationCheckErrorData.countryCode}
      />
    );
  }

  if (authenticationCheckError != null || isAuthenticated == null) {
    return (
      <section>
        <InternalErrorBlock />
      </section>
    );
  }

  const authService = new AuthService(isAuthenticated);

  // makes auth service accessible for non react tree code
  setAuthService(authService);

  return (
    <AuthServiceContext.Provider value={authService}>
      {children}
    </AuthServiceContext.Provider>
  );
};
