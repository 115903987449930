import deepEqual from 'fast-deep-equal';
import { TFunction } from 'i18next';
import { Maybe } from 'monet';
import * as R from 'ramda';

import {
  AccountStatus,
  PersonalBillStatus,
  VerificationMethod,
} from 'helpers/enums';

import {
  CRYPTO_AMOUNT_MIN_THRESHOLD,
  CRYPTO_AMOUNT_PRECISION,
} from 'constants/common';

export function isObject(o: any) {
  return o === Object(o) && !Array.isArray(o) && typeof o !== 'function';
}

export function isPromise<T = any>(o: any): o is Promise<T> {
  return isObject(o) && typeof o.then === 'function';
}

export function arraysEqual(a: any[], b: any[]) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export function equal<T>(a: T, b: T) {
  let equal: boolean;
  if (isObject(a) && isObject(b)) {
    equal = deepEqual(a, b);
  } else if (Array.isArray(a) && Array.isArray(b)) {
    equal = arraysEqual(a, b);
  } else {
    equal = a === b;
  }

  return equal;
}

export function difference<T>(setA: Set<T>, setB: Set<T>) {
  const _difference = new Set(setA);

  setB.forEach((elem) => _difference.delete(elem));

  return _difference;
}

export interface DiffOptions<T> {
  equalityFn?: (a: any, b: any, key: keyof T) => boolean;
  missing?: any;
}

export function getShallowDiff<T extends { [key: string]: any }>(
  changed: T,
  initial: T,
  options?: DiffOptions<T>
): Partial<T> {
  const equalityFn = (options && options.equalityFn) || deepEqual;

  const diff = Object.keys(changed).reduce(
    (previous, key) => {
      const changedValue = changed[key];
      const initialValue = initial[key];

      if (!equalityFn(initialValue, changedValue, key)) {
        // @ts-ignore
        previous[key] = changedValue;
      }

      return previous;
    },
    {} as { [K in keyof T]?: T[K] }
  );

  if (options && typeof options.missing !== 'undefined') {
    const diffKeys = difference(
      new Set(Object.keys(initial)),
      new Set(Object.keys(changed))
    );

    diffKeys.forEach((key) => {
      if (initial[key] !== options.missing) {
        // @ts-ignore
        diff[key] = options.missing;
      }
    });
  }

  return diff;
}

export function deepDiff<T extends { [key: string]: any }>(
  changed: T,
  initial: T,
  options?: DiffOptions<T>
) {
  const shallowChanges = getShallowDiff(changed, initial, options);

  const changes = {} as any;

  if (!isEmpty(shallowChanges)) {
    Object.keys(shallowChanges).forEach((key) => {
      const initialValue = initial[key];
      const changedValue = changed[key];

      if (
        (!isObject(changedValue) || !isObject(initialValue)) &&
        initialValue !== changedValue
      ) {
        changes[key] = changedValue;
        return;
      }

      const diff = deepDiff(
        changedValue as object,
        initialValue as object,
        options
      );

      if (!isEmpty(diff)) {
        changes[key] = diff;
      }
    });
  }

  return changes;
}

export function findInArrOfObj<T extends object, U extends keyof T>(
  arr: T[],
  predicate: U,
  value: T[U]
): T | undefined {
  return arr[arr.findIndex((item) => item[predicate] === value)];
}

export function mapValues<
  T extends { [key: string]: any },
  U extends { [K in keyof T]: K }[keyof T],
>(values: T, keys: U[], mapper: (value: { [K in U]: T[K] }[U]) => any) {
  return Object.keys(values).reduce(
    (previous, key) => {
      if ((keys as string[]).includes(key)) {
        // @ts-ignore
        previous[key] = mapper(values[key]);
      } else {
        // @ts-ignore
        previous[key] = values[key];
      }

      return previous;
    },
    {} as { [K in keyof T]: any }
  );
}

export function isEmpty(obj: object) {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
}

export function toCamel(s: string) {
  if (!s.includes('_')) return s;

  return s.replace(/([_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('_', '');
  });
}

export function toSnake(s: string) {
  return s
    .replace(/[\w]([A-Z])/g, function (m) {
      return m[0] + '_' + m[1];
    })
    .toLowerCase();
}

export function deepKeysTransformFactory(transform: (key: string) => string) {
  function deepKeysTransform<T extends { [key: string]: any } | any[]>(
    o: T,
    blacklists?: string[][]
  ): object {
    if (isObject(o)) {
      return Object.keys(o).reduce(
        (previous, current) => {
          const transformedKey = transform(current);

          const keyValue = (o as { [key: string]: any })[current];

          if (blacklists) {
            const keyBlacklists = blacklists
              .filter((bl) => bl[0] === transformedKey)
              .map((bl) => bl.slice(1));

            if (keyBlacklists.find((bl) => bl.length === 0)) {
              previous[transformedKey] = keyValue;
            } else {
              previous[transformedKey] = deepKeysTransform(
                keyValue,
                keyBlacklists
              );
            }
          } else {
            previous[transformedKey] = deepKeysTransform(keyValue);
          }

          return previous;
        },
        {} as { [key: string]: any }
      );
    } else if (Array.isArray(o)) {
      return o.map((i) => {
        return deepKeysTransform(i, blacklists);
      });
    }

    return o;
  }

  return deepKeysTransform;
}

export const camelize = deepKeysTransformFactory(toCamel);
export const snakify = deepKeysTransformFactory(toSnake);

export function generateRandomString(
  len: number,
  choices: string = '0123456789qwertyuiopasdfghjklzxcvbnmQWERTYUIOPASDFGHJKLZXCVBNM'
) {
  let result = '';

  const choicesLen = choices.length;

  for (let i = 0; i < len; i++) {
    result += choices.charAt(Math.floor(Math.random() * choicesLen));
  }
  return result;
}

export function prettyNumber(
  n: number,
  options?: { minFractionLength: number }
) {
  let prettyNumStr = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (options && options.minFractionLength) {
    const [integer, fraction] = prettyNumStr.split('.', 2);

    let zerosToAdd = 0;
    if (fraction == null) {
      zerosToAdd = options.minFractionLength;
    } else if (fraction.length < options.minFractionLength) {
      zerosToAdd = options.minFractionLength - fraction.length;
    }
    const prettyFraction = (fraction || '') + '0'.repeat(zerosToAdd);
    prettyNumStr = `${integer}.${prettyFraction}`;
  }
  return prettyNumStr;
}

export function is(x: any, y: any) {
  if (x === y) {
    return x !== 0 || y !== 0 || 1 / x === 1 / y;
  } else {
    return x !== x && y !== y; // eslint-disable-line no-self-compare
  }
}

export function shallowEqual(objA: any, objB: any) {
  if (is(objA, objB)) return true;

  if (
    typeof objA !== 'object' ||
    objA === null ||
    typeof objB !== 'object' ||
    objB === null
  ) {
    return false;
  }

  const keysA = Object.keys(objA);
  const keysB = Object.keys(objB);

  if (keysA.length !== keysB.length) return false;

  const hasOwn = Object.prototype.hasOwnProperty;
  for (let i = 0; i < keysA.length; i++) {
    if (!hasOwn.call(objB, keysA[i]) || !is(objA[keysA[i]], objB[keysA[i]])) {
      return false;
    }
  }

  return true;
}

export function roundDecimal(n: number, digits = 2) {
  return Math.round(n * 10 ** digits) / 10 ** digits;
}

export function roundUpDecimal(n: number, digits = 2) {
  return Math.ceil(n * 10 ** digits) / 10 ** digits;
}

export function numberToFixedString(n: number, _digits = 2) {
  return n.toFixed(2).replace(/(\.[0-9]*[1-9])0+$|\.0*$/, '$1');
}

export function containsOnlyNumbersAndDot(str: string, precision = 2) {
  if (precision === 0) {
    return new RegExp(`^([0-9]+)$`).test(str);
  }
  return new RegExp(`^([0-9]+(\\.([0-9]{1,${precision}})?)?)$`).test(str);
}

export const isMultipleLeadingZeros = (value: string): boolean => {
  return /^0{2,}/.test(value);
};

export function entries<T extends { [key: string]: any }>(
  obj: T
): [string, T[keyof T]][] {
  const ownProps = Object.keys(obj);
  let i = ownProps.length;
  const resArray = new Array(i); // preallocate the Array
  while (i--) resArray[i] = [ownProps[i], obj[ownProps[i]]];

  return resArray;
}

export const eternity = new Promise(() => {}) as Promise<any>;

export function filterObject<T extends { [key: string]: any }>(
  obj: T,
  filterFn: (key: keyof T, value: T[keyof T]) => boolean
) {
  const filtered = {} as { [key: string]: any };
  for (const [key, value] of entries(obj)) {
    if (filterFn(key, value)) {
      filtered[key] = value;
    }
  }

  return filtered;
}

export function uniqBy<T>(array: T[], key: keyof T) {
  return array.filter(
    (item, index, items) =>
      items.findIndex((el) => el[key] === item[key]) === index
  );
}

// FIXME: there is a bug in firefox
export function openInNewTab(href: string) {
  const a = document.createElement('a');
  a.href = href;
  a.setAttribute('target', '_blank');
  a.click();
  document.removeChild(a);
}

export function toTitleCase(str: string) {
  return str.replace(/([^\s:-])([^\s:-]*)/g, function (_$0, $1, $2) {
    return $1.toUpperCase() + $2.toLowerCase();
  });
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function enumKeys(E: any): string[] {
  return Object.keys(E).filter((k) => typeof E[k] === 'number');
}

export function enumValues(E: any): number[] {
  return enumKeys(E).map((k) => E[k]);
}

export function saveParseInt(value: any) {
  if (value == null) {
    return value;
  }

  const num = parseInt(value);

  if (isNaN(num)) {
    return;
  }

  return num;
}

export function uuid() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function checkObjectProperties<T extends string>(
  obj: any,
  props: T[]
): obj is { [K in T]: any } {
  return (
    isObject(obj) &&
    props.every((p) => Object.prototype.hasOwnProperty.call(obj, p))
  );
}

export function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj));
}

export function preventDefault(e: any) {
  if (e && isObject(e) && typeof e.preventDefault === 'function') {
    e.preventDefault();
  }
}

export function stopPropagation(e: any) {
  if (e && isObject(e) && typeof e.stopPropagation === 'function') {
    e.stopPropagation();
  }
}

export function valueChangeFactory() {
  let prev: any;

  return function (val: any) {
    const changed = prev !== val;

    if (changed) {
      prev = val;
    }

    return changed;
  };
}

export function minifyNumber(num: number, pretty = true) {
  const absNum = Math.abs(num);

  const stringify = (n: number) => {
    const rounded = roundDecimal(n);
    return num < 0
      ? '-'
      : '' + pretty
        ? prettyNumber(rounded)
        : rounded.toString();
  };

  if (absNum < 1e3) {
    return stringify(absNum);
  } else if (absNum < 1e6) {
    return `${stringify(absNum / 1e3)}К`;
  } else if (absNum < 1e9) {
    return `${stringify(absNum / 1e6)}M`;
  } else {
    return `${stringify(absNum / 1e9)}B`;
  }
}

export function replaceAll(str: string, search: string, replacement: string) {
  return str.split(search).join(replacement);
}

export const upperLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const lowerLetters = 'abcdefghijklmnopqrstuvwxyz';
export const digits = '012346789';
const specials = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

function getRandomInRange(top: number, bottom: number) {
  return Math.floor(Math.random() * (1 + top - bottom)) + bottom;
}

function pickRandomChar(str: string) {
  return str[getRandomInRange(0, str.length - 1)];
}

export function generateSecureString(
  choices: string[] = [specials, digits, upperLetters, lowerLetters],
  length?: number
) {
  const passLength = length ? length : getRandomInRange(11, 20);

  const pass: string[] = [];
  for (let i = 0; i < passLength - choices.length; i++) {
    pass.push(pickRandomChar(choices.join('')));
  }

  choices.forEach((chars) => {
    pass.splice(getRandomInRange(0, pass.length), 0, pickRandomChar(chars));
  });

  return pass.join('');
}

export function blobToFile(theBlob: Blob, fileName: string): File {
  return new File([theBlob], fileName, {
    lastModified: new Date().getTime(),
    type: theBlob.type,
  });
}

export const isMobileDevice =
  /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dolfin|Dolphin|Skyfire|Zune/i.test(
    navigator.userAgent
  );

export enum MobileOSType {
  ANDROID = 'android',
  IOS = 'ios',
  UNKNOWN = 'unknown',
}

export const getMobileOS = (): MobileOSType => {
  const { userAgent } = navigator;

  if (/android/i.test(userAgent)) {
    return MobileOSType.ANDROID;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return MobileOSType.IOS;
  }

  return MobileOSType.UNKNOWN;
};

export function timeConvert(time: number) {
  const hrs = ~~(time / 3600);
  const mins = ~~((time % 3600) / 60);
  const secs = ~~time % 60;

  return [hrs, mins, secs];
}

export function toUTC(datetime: string | number) {
  const myDate = new Date(datetime);
  const getUTC = myDate.getTime();
  const offset = myDate.getTimezoneOffset() * 60000;

  return getUTC + offset;
}

export const maskEmailAddress = (s: string): string => {
  const i = s.indexOf('@');

  let mail_1 = s.slice(0, i);
  const mail_2 = s.slice(i, s.length);

  const length = mail_1.length;

  if (length === 1) {
    mail_1 = mail_1.substring(0, 1) + '*'.repeat(3);
  } else if (length <= 4) {
    mail_1 =
      mail_1.substring(0, 1) +
      '*'.repeat(3) +
      mail_1.substring(length - 1, length);
  } else {
    mail_1 =
      mail_1.substring(0, 2) +
      '*'.repeat(3) +
      mail_1.substring(length - 2, length);
  }

  const result = mail_1 + mail_2;

  return result;
};

export const checkUserIsVerifiedOrTrusted = (
  status: AccountStatus,
  isTrusted: boolean
) => {
  return (
    status === AccountStatus.VERIFIED ||
    ((status === AccountStatus.EMAIL_VERIFIED ||
      status === AccountStatus.VERIFYING) &&
      isTrusted)
  );
};

export const checkUserVerificationIsRequired = (
  verificationMethod: VerificationMethod | null,
  status: AccountStatus
) => {
  return verificationMethod !== null && status !== AccountStatus.VERIFIED;
};

interface compressStringArgs {
  str: string;
  maxLength?: number;
  divider?: string;
}
export function compressString({
  str,
  maxLength = 80,
  divider = '...',
}: compressStringArgs) {
  const strLength = str.length;
  if (strLength <= maxLength) return str;

  const diff = str.length - maxLength;
  const center = str.length / 2;

  const firstPart = str.slice(0, center - diff / 2);
  const lastPart = str.slice(center + diff / 2);

  return firstPart + divider + lastPart;
}

export const truncateStringNumber = (
  stringNumber: string | number,
  digits = CRYPTO_AMOUNT_PRECISION
) => {
  if (typeof stringNumber === 'number') {
    stringNumber = String(stringNumber);
  }

  if (!stringNumber.includes('.')) {
    return stringNumber;
  }
  const [integer, fraction] = stringNumber.split('.');

  const slicedFraction = fraction.slice(0, digits);

  if (Number(slicedFraction) === 0) {
    return integer + '.0';
  }

  return integer + '.' + slicedFraction;
};

export const roundUpStringNumber = (stringNumber: string, digits = 8) => {
  // eslint-disable-next-line
  const [_, fraction] = stringNumber.split('.');

  if (!stringNumber.includes('.') || fraction.length <= digits) {
    return stringNumber;
  }

  return String(roundUpDecimal(+stringNumber, digits));
};

export function getBillStatusName(status: PersonalBillStatus, t: TFunction) {
  return t([
    `personalHistory:bill.statuses.${status}`,
    'personalHistory:bill.statuses.unknown',
  ]);
}

export function countDecimalPlaces(number: number | string): number {
  const decimalPart =
    typeof number === 'number'
      ? number.toString().split('.')[1]
      : number.split('.')[1];
  return decimalPart ? decimalPart.length : 0;
}

function nonExponentialFormat(num: number | string | undefined) {
  if (num === undefined) return num;

  let numStr = num.toString();

  if (numStr.includes('e-')) {
    const [base, exp] = numStr.split('e-');
    const zeros = parseInt(exp, 10) - 1;
    numStr = '0.' + '0'.repeat(zeros) + base.replace('.', '');
  }

  return numStr;
}

const maybeNonExponentialFormat = (num: number | string | undefined) =>
  Maybe.fromEmpty(nonExponentialFormat(num));

export const formatCryptoAmountCashback = (
  amount: string | number | undefined
) =>
  Maybe.fromEmpty(amount)
    .flatMap(maybeNonExponentialFormat)
    .map(truncateStringNumber)
    .getOrElse('0');

const returnOrZero = R.curry((threshold: number, input: number) =>
  input < threshold ? 0 : input
);

const cryptoValuesLessThanZero = returnOrZero(CRYPTO_AMOUNT_MIN_THRESHOLD);

const moreThemZero = R.lt(0);

export const isCryptoValueMoreThanZero = R.pipe(
  Number,
  cryptoValuesLessThanZero,
  moreThemZero
);

export const getCurrentDomainWithoutSubdomains = () => {
  return window.location.hostname.split('.').slice(-2).join('.');
};
