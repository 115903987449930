export const SYSTEM_CURRENCY = 'KVT';
export const SYSTEM_CURRENCY_NAME = 'Kvitum';

export const CRYPTO_AMOUNT_PRECISION = 8;
export const FIAT_AMOUNT_PRECISION = 2;

export const CRYPTO_AMOUNT_MIN_THRESHOLD = Math.pow(
  10,
  -CRYPTO_AMOUNT_PRECISION
);

export const DESIRED_FIAT_CURRENCY_CODE = 840;
