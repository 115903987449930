import createStore from 'zustand';

import { immer } from 'helpers/zustand';

export interface AuthStoreState {
  encodedBillId: string | undefined;
  encodedCryptoBillId: string | undefined;
  next: string | undefined;
}

export interface AuthStoreActions {
  setEncodedBillId: (value: string | undefined) => void;
  setEncodedCryptoBillId: (value: string | undefined) => void;
  setNextParam: (value: string | undefined) => void;

  resetBills: () => void;
}

export const initialStoreState = {
  encodedBillId: undefined,
  encodedCryptoBillId: undefined,
  next: undefined,
};

export const useAuthStore = createStore(
  immer<AuthStoreState & AuthStoreActions>((set) => ({
    ...initialStoreState,

    setEncodedBillId: (value) =>
      set((state) => void (state.encodedBillId = value)),

    setEncodedCryptoBillId: (value) =>
      set((state) => void (state.encodedCryptoBillId = value)),

    setNextParam: (value) => set((state) => void (state.next = value)),

    resetBills: () =>
      set((state) => ({ ...initialStoreState, next: state.next })),
  }))
);

export function resetBillsAuthStore() {
  const { resetBills } = useAuthStore.getState();

  resetBills();
}
