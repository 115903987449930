import axios, { CancelTokenSource } from 'axios';

import { uuid } from 'helpers/common';

export type Subscriber = (isAuthenticated: boolean) => void;

export class BaseAuthService {
  public isAuthenticated: boolean;
  public loginKey: string | undefined;
  private subscribers: Subscriber[];
  protected readonly cancelSource: CancelTokenSource;

  constructor(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.subscribers = [];

    this.cancelSource = axios.CancelToken.source();

    this.setLoginKey();
  }

  private setLoginKey = () => {
    if (this.isAuthenticated) {
      this.loginKey = uuid();
    } else {
      this.loginKey = undefined;
    }
  };

  private notify = () => {
    this.subscribers.forEach((subscriber) => subscriber(this.isAuthenticated));
  };

  setIsAuthenticated = (isAuthenticated: boolean) => {
    this.isAuthenticated = isAuthenticated;

    this.setLoginKey();
    this.notify();
  };

  subscribe = (subscriber: Subscriber, immediateCall?: boolean) => {
    this.subscribers.push(subscriber);

    if (immediateCall) subscriber(this.isAuthenticated);

    return () => {
      const subscriberIndex = this.subscribers.findIndex(
        (c) => c === subscriber
      );

      if (subscriberIndex === -1) return;

      this.subscribers.splice(subscriberIndex, 1);
    };
  };

  cancelRequest = (msg?: string) => {
    this.cancelSource.cancel(msg);
  };
}
