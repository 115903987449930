export enum ShopStatus {
  NEW = 1,
  ACTIVE = 2,
  BLOCKED = 3,
  DELETED = 4,
  VERIFIED = 5,
}

export enum FormStatus {
  INITIAL = 1,
  SAVED,
  ERROR,
  NOT_CHANGED,
}

export enum ShopPaymentStatus {
  WAITING = 3,
  SUCCESS = 5,
  REJECTED = 6,
  REFUNDED = 11,
}

export enum ShopCryptoPaymentStatus {
  WAITING = 2,
  SUCCESS = 3,
  REJECTED = 4,
  REFUNDED = 6,
}

export enum ShopDepositStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
  REFUNDED,
  HELD,
  CAPTURED,
  EXPIRED,
  REFUNDING,
}

export enum ShopCryptoDepositStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
  CAPTURED,
  RISKY,
  REFUNDING,
  REFUNDED,
  EXPIRED,
}

export enum ShopStatementType {
  INVOICE = 1,
  WITHDRAW,
  SYSTEM,
}

export enum AccountStatus {
  NEW = 1,
  EMAIL_VERIFIED,
  VERIFIED,
  BLOCKED,
  VERIFYING,
}

export enum VerificationMethod {
  MANUALLY = 1,
  JUMIO = 2,
  VCHECK = 3,
  VCHECK_V1 = 4,
}

export enum TwoFactorType {
  NONE,
  GCODE,
  SMS,
}

export enum TwoFactorAction {
  AUTH = 2,
  SHOP_SETTINGS = 20,
  SHOP_INPUT_PAY_METHODS = 22,
  PAYMENTS = 38,
}

export enum TwoFactorError {
  INVALID_TWO_FACTOR = 1005,
  INVALID_CODE = 1034,
  EXPIRED_TOKEN = 1035,
  NO_SMS_ATTEMPTS = 1039,
  SMS_OFF = 1040,
  PHONE_IN_USE = 1041,
}

export enum MassPaymentError {
  UNKNOWN = 1,
  ACCOUNT,
  AMOUNT,
  ALIAS,
  ADD_ONS,
  DESCRIPTION,
}

export enum MediaType {
  MOBILE,
  TABLET,
  DESKTOP,
}

export enum MediaQuery {
  MOBILE = '(max-width: 768px)',
  TABLET = '(min-width: 768px) and (max-width: 928px)',
  DESKTOP = '(min-width: 928px)',
}

export enum TokenType {
  REGISTRATION = 1,
  RECOVERY = 2,
  REGISTRATION_CODE = 8,
  RECOVERY_CODE = 9,
  NOTIFY = 5,
}

export enum AccountVerificationDocumentType {
  PASS = 1,
  INT_PASS,
  ID_CARD,
}

export enum PersonalDepositStatus {
  WAITING = 2,
  SUCCESS = 4,
  REJECTED = 6,
  CAPTURED = 7,
  REFUNDED = 8,
  HELD = 9,
}

export enum PersonalCryptoDepositStatus {
  WAITING = 1,
  SUCCESS,
  REJECTED,
  CAPTURED,
  RISKY,
  REFUNDING,
  REFUNDED,
}

export enum PersonalDepositPayMethodType {
  ACCOUNT_TRANSFER = 'account_transfer',
  TRANSFER = 'transfer',
  DEPOSIT = 'deposit',
}

export enum PersonalCryptoDepositType {
  ACCOUNT_TRANSFER = 'account_transfer',
  DEPOSIT = 'deposit',
}

export enum PersonalCryptoPaymentType {
  ACCOUNT_TRANSFER = 'account_transfer',
  PAYOUT = 'payout',
  BASE_PAYOUT = 'base_payout',
}

export enum PersonalBillStatus {
  WAITING = 1,
  PAID,
  CANCELED,
  EXPIRED,
}

export enum BillPaymentStatus {
  WAITING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum PersonalPaymentStatus {
  WAITING = 3,
  SUCCESS = 5,
  REJECTED = 6,
  REFUNDED = 11,
}

export enum PersonalCryptoPaymentStatus {
  WAITING = 2,
  SUCCESS = 3,
  REJECTED = 4,
  REFUNDED = 9,
}

export enum PersonalStatementType {
  INVOICE = 1,
  WITHDRAW,
  SYSTEM,
}

export enum CardStatus {
  NEW = 1,
  VERIFYING,
  VERIFIED,
  HIDDEN,
}

export enum Permission {
  BALANCE = 'balances',
  HISTORY = 'reports',
  SHOP_SETTINGS = 'tech_settings',
  ANALYTICS = 'analytics',
  HISTORY_DOWNLOAD = 'download',
}

export enum RecaptchaVersion {
  V2 = 2,
  V3 = 3,
}

export enum RecaptchaV3Action {
  LOGIN = 'login',
  REGISTRATION = 'registration',
  RECOVERY = 'recovery',
}

export enum LoginProviders {
  QR = 'qr',
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  APPLE = 'apple',
}

export enum LastLoginType {
  EMAIL = 1,
  QRCODE = 2,
  GOOGLE = 3,
  FACEBOOK = 4,
  APPLE = 5,
}

export enum PayeeAccountType {
  PHONE = 1,
  WALLET,
  EMAIL,
}

export enum PaymethodFilter {
  ALL,
  CARDS,
  FIAT,
  CRYPTO,
}

export enum BalanceFilter {
  ALL,
  SELECTED,
  FIAT,
  CRYPTO,
}

export enum DepositRedirectMethods {
  OFFLINE = 'OFFLINE',
}

export enum CardType {
  WORLD,
  VISA,
  MASTERCARD,
}

export enum OptionalBusinessModule {
  TERMINAL = 'terminal',
}
