import { Maybe } from 'monet';
import * as R from 'ramda';

import maxpaykzFavicon from 'imgs/maxpaykz/favicon.png';
import maxpaykzLogoDesktop from 'imgs/maxpaykz/logo-desk.svg';
import maxpaykzLogoMobile from 'imgs/maxpaykz/logo-mob.png';

type TMirroringConfig = {
  logoDesktop: string;
  logoMobile: string;
  favicon: string;
  loader: string;
  projectName: string;
  notifications: boolean;
};

const mirroringConfig: Record<string, TMirroringConfig> = {
  ['test-business.maxpaykz.com']: {
    logoDesktop: maxpaykzLogoDesktop,
    logoMobile: maxpaykzLogoMobile,
    favicon: maxpaykzFavicon,
    loader: maxpaykzFavicon,
    projectName: 'maxpay',
    notifications: false,
  },
  ['business.maxpaykz.com']: {
    logoDesktop: maxpaykzLogoDesktop,
    logoMobile: maxpaykzLogoMobile,
    favicon: maxpaykzFavicon,
    loader: maxpaykzFavicon,
    projectName: 'maxpay',
    notifications: false,
  },
  // ['localhost']: {
  //   logoDesktop: maxpaykzLogoDesktop,
  //   logoMobile: maxpaykzLogoMobile,
  //   favicon: maxpaykzFavicon,
  //   loader: maxpaykzFavicon,
  //   twoFAName: 'local',
  //   notifications: true,
  // },
};

export const getMirroringConfig = <K extends keyof TMirroringConfig>(key: K) =>
  Maybe.fromEmpty(mirroringConfig[window.location.hostname])
    .flatMap((config) => Maybe.fromEmpty(R.path([key], config)))
    .orUndefined();

export const getMirroringConfigWithDefault = <
  K extends keyof TMirroringConfig,
  D extends TMirroringConfig[K],
>(
  key: K,
  defaultValue: D
) => Maybe.fromEmpty(getMirroringConfig(key)).getOrElse(defaultValue);
