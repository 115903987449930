const MOUSEFLOW_KEY = process.env.REACT_APP_MOUSEFLOW_KEY;

const widgetScript = (key: string) => `
  window._mfq = window._mfq || [];
      (function() {
        var mf = document.createElement("script");
        mf.type = "text/javascript"; mf.defer = true;
        mf.src = "//cdn.mouseflow.com/projects/${key}.js";
        document.getElementsByTagName("head")[0].appendChild(mf);
      })();
`;

export const mouseflowWidget = {
  key: MOUSEFLOW_KEY,
  add: function () {
    if (this.key == null) return;

    const script = document.createElement('script');
    script.innerHTML = widgetScript(this.key);
    document.body.appendChild(script);
  },

  addVariable: function (key: string, value: string | number) {
    const script = document.createElement('script');
    script.innerHTML = `window._mfq = window._mfq || [];
      window._mfq.push(["setVariable", "${key}", "${value}", "session", false]);`;
    document.body.appendChild(script);
  },
};
