import { TwoFactor } from 'api/base';
import {
  AuthAddOns,
  CodeRequiredForm,
  LoginCredentials,
  LoginCredentialsWithRecaptcha,
  codeRequiredLogin,
  login,
  logout,
  twoFactorLogin,
} from 'api/shop/auth';

import { BaseAuthService } from './service';
import { resetBillsAuthStore } from './store';

export type Subscriber = (isAuthenticated: boolean) => void;

export class ShopAuthService extends BaseAuthService {
  login = async (
    credentials: LoginCredentialsWithRecaptcha,
    addOns?: AuthAddOns
  ) => {
    const resp = await login.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  twoFactorLogin = async (
    credentials: LoginCredentials,
    twoFactor: TwoFactor,
    addOns?: AuthAddOns
  ) => {
    const resp = await twoFactorLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      addOns,
      twoFactor
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  codeRequiredLogin = async (
    credentials: LoginCredentials,
    codeRequired: CodeRequiredForm,
    addOns?: AuthAddOns
  ) => {
    const resp = await codeRequiredLogin.call(
      { cancelToken: this.cancelSource.token },
      credentials,
      codeRequired,
      addOns
    );

    resetBillsAuthStore();
    this.setIsAuthenticated(true);

    return resp;
  };

  logout = async () => {
    const resp = await logout.call({ cancelToken: this.cancelSource.token });

    this.setIsAuthenticated(false);

    return resp;
  };
}
