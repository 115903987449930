import type { ShopAuthService } from 'modules/authService/business';
import type { PersonalAuthService } from 'modules/authService/personal';

let authService: PersonalAuthService | ShopAuthService | undefined;

export function setAuthService(service: PersonalAuthService | ShopAuthService) {
  authService = service;
}

export { authService };
