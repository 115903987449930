import React from 'react';

import App from 'app/app';
import 'libs/i18n';
import 'libs/posthog';
import { createRoot } from 'react-dom/client';

Promise.all([
  import(`../themes/${process.env.THEME}`),
  import(
    `../themes/${process.env.THEME}/entrypoints/${process.env.BUILD_TYPE}`
  ),
])
  .then(([themeModule, routesModule]) => {
    const container = document.getElementById('root');
    if (container) {
      const root = createRoot(container);
      root.render(
        <App theme={themeModule.default} routes={routesModule.default} />
      );
    }
  })
  .catch((e) => {
    throw new Error(e as string);
  });
