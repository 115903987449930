// @ts-ignore
import { setSentryBreadcrumbsInfo as setSentryBreadcrumbs } from './setSentryBreadcrumbs';

const polyfills = [];

if (!Object.entries) {
  setSentryBreadcrumbs('object-entries');
  polyfills.push(
    import(
      /* webpackChunkName: "polyfill-object-entries" */ 'polyfills/objectEntries'
    )
  );
}

if (!Object.assign) {
  setSentryBreadcrumbs('object-assign');
  polyfills.push(
    import(
      /* webpackChunkName: "polyfill-object-assign" */ 'polyfills/objectAssign'
    )
  );
}

if (!Array.prototype.includes) {
  setSentryBreadcrumbs('array-includes');
  polyfills.push(
    import(
      /* webpackChunkName: "polyfill-array-includes" */ 'polyfills/arrayIncludes'
    )
  );
}

if (!Array.prototype.findIndex) {
  setSentryBreadcrumbs('array-findIndex');
  polyfills.push(
    import(
      /* webpackChunkName: "polyfill-array-findIndex" */ 'polyfills/arrayFindIndex'
    )
  );
}

if (!Array.prototype.find) {
  setSentryBreadcrumbs('array-find');
  polyfills.push(
    import(/* webpackChunkName: "polyfill-array-find" */ 'polyfills/arrayFind')
  );
}

if (typeof Set === 'undefined' || typeof Set.prototype.keys !== 'function') {
  setSentryBreadcrumbs('polyfills-set');
  polyfills.push(
    import(/* webpackChunkName: "polyfill-set" */ 'polyfills/set')
  );
}

if (typeof URL !== 'function') {
  setSentryBreadcrumbs('polyfills-url');
  polyfills.push(
    import(/* webpackChunkName: "polyfill-url" */ 'polyfills/url')
  );
}

if (!window.Intl) {
  setSentryBreadcrumbs('polyfills-intl');
  polyfills.push(
    import(/* webpackChunkName: "polyfill-intl" */ 'polyfills/intl')
  );
}

export default polyfills;
