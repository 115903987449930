import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ForbiddenBlock = styled.div`
  width: 100%;
  max-width: 1268px;
  margin: 0 auto;
`;

export const ForbiddenHeader = styled.header`
  margin: ${({ theme }) => theme.spacing.primitives['s-20']} auto
    ${({ theme }) => theme.spacing.primitives['s-80']};
  padding: ${({ theme }) => theme.spacing.primitives['s-12']}
    ${({ theme }) => theme.spacing.primitives['s-16']};
  width: 100%;
  box-sizing: border-box;

  @media (${device.mobileXXL}) {
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-48']};
    svg,
    img {
      width: 115px;
    }
  }
`;

export const ForbiddenIcon = styled.div`
  max-width: 60%;

  & > svg {
    width: 100%;
    height: 100%;
  }

  @media (${device.mobileXXL}) {
    width: 155px;
    height: 155px;
  }
`;

export const ForbiddenBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  max-width: 835px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.spacing.primitives['s-16']}
    ${({ theme }) => theme.spacing.primitives['s-32']};
`;

export const ForbiddenTitle = styled.p`
  color: ${({ theme }) => theme.colors['text-primary']};
  font: ${({ theme }) => theme.typography['display-large']};
  margin: ${({ theme }) => theme.spacing.primitives['s-56']} 0 0;

  @media (${device.mobileXXL}) {
    margin: ${({ theme }) => theme.spacing.primitives['s-48']} 0 0;
    font: ${({ theme }) => theme.typography['display-medium']};
  }
`;

export const ForbiddenSubtitle = styled.p`
  color: ${({ theme }) => theme.colors['text-subtle']};
  font: ${({ theme }) => theme.typography['heading-small']};
  margin: ${({ theme }) => theme.spacing.primitives['s-24']} 0 0;

  @media (${device.mobileXXL}) {
    margin: ${({ theme }) => theme.spacing.primitives['s-12']} 0 0;
    font: ${({ theme }) => theme.typography['paragraph-medium']};
  }
`;

export const ForbiddenInfo = styled.div`
  color: ${({ theme }) => theme.colors['text-subtle']};
  font: ${({ theme }) => theme.typography['paragraph-medium']};
  margin-top: ${({ theme }) => theme.spacing.primitives['s-20']};

  & > p {
    margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-08']};

    span {
      font: ${({ theme }) => theme.typography['label-medium']};
    }
  }

  @media (${device.mobileXXL}) {
    margin-top: ${({ theme }) => theme.spacing.primitives['s-16']};
    font: ${({ theme }) => theme.typography['paragraph-small']};

    & > p {
      margin: 0 0 ${({ theme }) => theme.spacing.primitives['s-04']};

      span {
        font: ${({ theme }) => theme.typography['label-small']};
      }
    }
  }
`;
