import * as Sentry from '@sentry/browser';

import polyfills from 'polyfills';

import 'libs/sentry';
// Promise is required for correct lazy loading of other polyfills
// so we need to always include it before other polyfills
import 'polyfills/promise/polyfill';

async function showReactDOM() {
  try {
    await Promise.all(polyfills);
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'polyfills',
      message: 'Unexpected error: polyfills failed to install',
      level: 'error',
    });

    Sentry.captureException(error);
  } finally {
    require('app/renderer');
  }
}

showReactDOM();
