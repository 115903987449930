import React, { FC, PropsWithChildren, useEffect, useState } from 'react';

export interface DelayedRenderProps {
  delay: number;
}

export const DelayedRender: FC<PropsWithChildren<DelayedRenderProps>> = ({
  delay,
  children,
}) => {
  const [show, setShow] = useState(delay <= 0);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (delay > 0) {
      timeout = setTimeout(() => setShow(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [delay]);

  return show ? <>{children}</> : null;
};
