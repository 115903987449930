import posthog from 'posthog-js';

const IS_LOCAL_HOST = ['localhost', '127.0.0.1'].some((url) =>
  window.location.href.includes(url)
);

if (
  !IS_LOCAL_HOST &&
  process.env.REACT_APP_POSTHOG_KEY &&
  process.env.REACT_APP_POSTHOG_HOST
) {
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
  });
}
