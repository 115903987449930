import React, { FC } from 'react';

import { CssBaseline, ThemeName } from '@involve-software/uikit';
import * as Sentry from '@sentry/react';

import { Matcher } from 'navi';

import { useReplaceFavicon } from 'hooks/favicon';

import {
  getMirroringConfig,
  getMirroringConfigWithDefault,
} from 'helpers/mirroring';

import { InternalErrorBlock } from 'components/internalError';

import { AuthServiceProvider } from 'modules/authService/provider';

import { Routes } from 'router/routes';
import { Theme } from 'themes/contracts';

import GlobalStyles from './GlobalStyles';
import { ThemeProvider } from './themeProvider';

console.log(getMirroringConfigWithDefault('projectName', APP_VERSION));
const themeKey = (process.env.REACT_APP_THEME as ThemeName) || 'kvitum';

const alternativeFaviconForMirror = getMirroringConfig('favicon');

const App: FC<{ theme: Theme; routes: Matcher<any> }> = ({ theme, routes }) => {
  useReplaceFavicon(alternativeFaviconForMirror);

  return (
    <ThemeProvider theme={theme} themeKey={themeKey}>
      <GlobalStyles theme={theme} />
      <CssBaseline />
      <Sentry.ErrorBoundary
        fallback={
          <section>
            <InternalErrorBlock />
          </section>
        }
      >
        <AuthServiceProvider>
          <Routes routes={routes} />
        </AuthServiceProvider>
      </Sentry.ErrorBoundary>
    </ThemeProvider>
  );
};

export default Sentry.withProfiler(App);
