import { device } from 'components/global/MediaQuery';

import styled from 'styled-components/macro';

export const ScMobileMenuHeight = '70px';

export const ScMobileSideBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: ${ScMobileMenuHeight};
  background: ${({ theme }) => theme.colors['layer-01']};
  border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: 110;
  box-sizing: border-box;
  .sidebar-mobile-link {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin: ${({ theme }) => theme.spacing.primitives['s-20']};
    align-items: center;
    color: ${({ theme }) => theme.colors['text-primary']};
    background: none;
    border: 0;
    box-sizing: border-box;
    padding: 0;
    cursor: pointer;
    font: ${({ theme }) => theme.typography['label-x-small']};
    @media (${device.mobileXXL}) {
      margin: ${({ theme }) => theme.spacing.primitives['s-04']};
    }
    &.active {
      color: ${({ theme }) => theme.colors['text-brand']} !important;
      svg *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.colors['icon-brand']};
      }
      svg *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.colors['icon-brand']};
      }
    }
    svg {
      margin: 0 auto ${({ theme }) => theme.spacing.primitives['s-04']} auto;
      *[class$='svg-fill'] {
        fill: ${({ theme }) => theme.colors['icon-secondary-strong']};
      }
      *[class$='svg-stroke'] {
        stroke: ${({ theme }) => theme.colors['icon-secondary-strong']};
      }
    }
    &:hover {
      text-decoration: none;
    }
  }
`;

export const ScMoreBlock = styled.div`
  position: absolute;
  z-index: 99;
  background: ${({ theme }) => theme.colors['layer-01']};
  width: 100%;
  height: auto;
  max-height: calc(100vh - ${ScMobileMenuHeight});
  bottom: ${ScMobileMenuHeight};
  padding: ${({ theme }) => `
     ${theme.spacing.primitives['s-28']}
     ${theme.spacing.primitives['s-28']}
     ${theme.spacing.primitives['s-48']}
  `};
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }) => theme.colors['border-subtle-01']};
  border-radius: ${({ theme }) => theme.radius['radius-pr-strong']}
    ${({ theme }) => theme.radius['radius-pr-strong']} 0 0;
  @media (max-height: 471px) {
    padding: ${({ theme }) => `
     ${theme.spacing.primitives['s-10']}
     ${theme.spacing.primitives['s-28']}
     ${theme.spacing.primitives['s-28']}
  `};
  }
  .close {
    position: absolute;
    right: ${({ theme }) => theme.spacing.primitives['s-10']};
    top: ${({ theme }) => theme.spacing.primitives['s-10']};
    font: ${({ theme }) => theme.typography['display-small']};
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .header-links {
    display: flex;
    flex-direction: column;
    margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
    @media (max-height: 471px) {
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
    }
    a,
    button {
      color: ${({ theme }) => theme.colors['text-subtle']};
      margin-bottom: ${({ theme }) => theme.spacing.primitives['s-24']};
      text-align: left;
      font: ${({ theme }) => theme.typography['label-x-small']};
      @media (max-height: 471px) {
        margin-bottom: ${({ theme }) => theme.spacing.primitives['s-16']};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sidebar-mobile-link {
    flex-direction: row;
    justify-content: left;
    margin: ${({ theme }) => theme.spacing.primitives['s-24']} auto;
    width: auto;
    @media (max-height: 471px) {
      margin: ${({ theme }) => theme.spacing.primitives['s-16']} auto;
    }
    span {
      font: ${({ theme }) => theme.typography['label-medium']};
      @media (max-height: 471px) {
        font: ${({ theme }) => theme.typography['label-small']};
      }
    }
    svg {
      margin: 0;

      &:first-child {
        margin: 0 ${({ theme }) => theme.spacing.primitives['s-16']} 0 0;
      }
    }
  }
`;
